import { Navigate, Outlet, createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "common/app";
import Retrieval from "verify_portal/retrieval";
import RetrievalRequest from "verify_portal/retrieval/request";
import RetrievalDetails from "verify_portal/retrieval/details";

const Root = () => (
  <App isAuthenticated={false} mobileSupported>
    <Retrieval>
      <Outlet />
    </Retrieval>
  </App>
);

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      { path: "/", element: <RetrievalRequest /> },
      { path: "/details", element: <RetrievalDetails /> },
      { path: "*", element: <Navigate replace to="/" /> },
    ],
  },
]);

export default () => {
  return <RouterProvider router={router} />;
};
